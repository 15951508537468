export const estados = [
  // 0
  {
    title: "Solicitud de Cotización",
    subtitle: "Inicio",
    value: 1,
    variant: "danger",
    availableTo: [1000],
    user: "Supervisor",
  },

  // 1
  {
    title: "Asignación de Partidas",
    alterTitle: "Asignación de Partidas (Supervisor)",
    value: 2,
    variant: "warning",
    availableTo: [1000],
    user: "Supervisor",
  },
  // 2
  {
    title: "Registro del Local",
    alterTitle: "Visita Supervisor",
    value: 3,
    variant: "warning",
    availableTo: [1000],
    user: "Supervisor",
  },
  // 3
  {
    title: "Estimación (Contratista)",
    alterTitle: "Pendiente Estimación (Contratista)",
    value: 4,
    variant: "secondary",
    availableTo: [1000],
    user: "Contratista",
  },
  // 4
  {
    title: "Estimación (Administrador)",
    alterTitle: "Pendiente Estimación (Administrador)",
    value: 5,
    variant: "info",
    availableTo: [1000],
    user: "Administrador",
  },
  // 5
  {
    title: "Pendiente de Aprobación",
    value: 6,
    variant: "dark",
    availableTo: [7, 1000],
    user: "Administrador",
  },
  // 6
  {
    title: "Aprobado",
    alterTitle: "Aprobado - Contratista debe iniciar",
    value: 7,
    variant: "info",
    availableTo: [1000],
    user: "Contratista",
  },
  // 7
  {
    title: "Ejecutado",
    alterTitle: "Ejecutado - Pendiente Revisión (Supervisor)",
    value: 8,
    variant: "warning",
    availableTo: [9, 1000],
    user: "Supervisor",
  },
  // 8
  {
    title: "Corrección Pendiente",
    alterTitle: "Corrección Pendiente (Contratista)",
    value: 9,
    variant: "secondary",
    availableTo: [1000],
    user: "Contratista",
  },
  // 9
  {
    title: "Fotos Finales",
    alterTitle: "Fotos Finales (Supervisor)",
    value: 10,
    variant: "warning",
    availableTo: [1000],
    user: "Supervisor",
  },
  // 10
  {
    title: "Trabajo Completado",
    alterTitle: "Trabajo Completado - Obtener Firma",
    value: 11,
    variant: "info",
    availableTo: [1000],
    user: "Supervisor",
  },
  {
    title: "Firma Subida",
    alterTitle: "Firma Subida - Se debe realizar pagos (Finanzas)",
    value: 12,
    variant: "warning",
    availableTo: [1000],
    user: "Administrador",
  },
  {
    title: "Pagado Contratista",
    alterTitle: "Pagado Contratista - Se debe subir orden de compra",
    value: 13,
    variant: "info",
    availableTo: [1000],
    user: "Administrador",
  },
  {
    title: "Compra Generada",
    alterTitle: "Compra Generada - Se debe subir Factura",
    value: 14,
    variant: "info",
    availableTo: [1000],
    user: "Administrador",
  },

  {
    title: "Facturado",
    value: 15,
    variant: "success",
    availableTo: [],
    user: "",
  },

  // 10
  {
    title: "Rechazado",
    value: 1000,
    variant: "danger",
    availableTo: [],
    user: "",
  },
];

export const estadosTareas = [
  {
    title: "Pendiente",
    value: 1,
    variant: "secondary",
    icon: "far fa-clock",
  },
  {
    title: "Ejecutado",
    value: 2,
    variant: "primary",
    icon: "fas fa-clipboard-list",
  },
  {
    title: "Se debe corregir",
    value: 3,
    variant: "warning",
    icon: "fas fa-wrench",
  },
  {
    title: "APROBADO",
    value: 4,
    variant: "success",
    icon: "fas fa-check"
  },
  {
    title: "POR REVISAR",
    value: 0,
    variant: "info",
    icon: "fas fa-stopwatch"
  },
];

export const formatosDisponible = [{ text: "Vivanda" }, { text: "Plaza Vea" }];
