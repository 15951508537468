import UpdateAsignacion from "@/apollo/mutations/asignaciones/UpdateAsignacion.gql";

var mixin = {
  methods: {
    privateActualizarEstadoAsignacionCodigo(nuevoEstado, item) {
      // Backend no permite actualizar estado si no es admin
      let {
        descripcionServicio,
        direccionLocal,
        latitud,
        longitud,

        contratistaId,
        supervisorId,
        finanzasId,
        localId,
        tiempoEntrega,
        ofertaValida,
        correoAtencion,
        celularAtencion,
        codigoTicket,
        observaciones,
        comentariosAdicionales,
        atencion,
        rango,
        fechaInicioOriginal,
        fechaInicioVigente,
        fechaPago,
        numerodeTecnicos,
        bono,
        costoAdicional,
        nroOperacion,
      } = item;

      return new Promise((resolveFunction, rejectFunction) => {
        this.$apollo
          .mutate({
            mutation: UpdateAsignacion,
            variables: {
              input: {
                descripcionServicio,
                direccionLocal,
                latitud,
                longitud,
                estado: nuevoEstado,
                contratistaId,
                supervisorId,
                finanzasId,
                localId,
                tiempoEntrega,
                ofertaValida,
                correoAtencion,
                celularAtencion,
                codigoTicket,
                observaciones,
                comentariosAdicionales,
                atencion,
                rango,
                fechaInicioOriginal,
                fechaInicioVigente,
                fechaPago,
                numerodeTecnicos,
                bono,
                costoAdicional,
                nroOperacion,
              },
            },
          })
          .then((response) => {
            resolveFunction(response);
          })
          .catch((err) => {
            rejectFunction(err);
          });
      });
    },
    privateActualizarEstadoAsignacion(nuevoEstado) {
      // Backend no permite actualizar estado si no es admin
      let {
        descripcionServicio,
        direccionLocal,
        latitud,
        longitud,

        contratistaId,
        supervisorId,
        finanzasId,
        localId,
        tiempoEntrega,
        ofertaValida,
        correoAtencion,
        celularAtencion,
        codigoTicket,
        observaciones,
        comentariosAdicionales,
        atencion,
        rango,
        fechaInicioOriginal,
        fechaInicioVigente,
        fechaPago,
        numerodeTecnicos,
        bono,
        costoAdicional,
        nroOperacion,
      } = this.asignacion;

      return new Promise((resolveFunction, rejectFunction) => {
        this.$apollo
          .mutate({
            mutation: UpdateAsignacion,
            variables: {
              input: {
                descripcionServicio,
                direccionLocal,
                latitud,
                longitud,
                estado: nuevoEstado,
                contratistaId,
                supervisorId,
                finanzasId,
                localId,
                tiempoEntrega,
                ofertaValida,
                correoAtencion,
                celularAtencion,
                codigoTicket,
                observaciones,
                comentariosAdicionales,
                atencion,
                rango,
                fechaInicioOriginal,
                fechaInicioVigente,
                fechaPago,
                numerodeTecnicos,
                bono,
                costoAdicional,
                nroOperacion,
              },
            },
          })
          .then((response) => {
            resolveFunction(response);
          })
          .catch((err) => {
            rejectFunction(err);
          });
      });
    },
    privateActualizarEstadoAsignacionSeleccionada(asignacion, nuevoEstado) {
      // Backend no permite actualizar estado si no es admin
      let {
        descripcionServicio,
        direccionLocal,
        latitud,
        longitud,

        contratistaId,
        supervisorId,
        finanzasId,
        localId,
        tiempoEntrega,
        ofertaValida,
        correoAtencion,
        celularAtencion,
        codigoTicket,
        observaciones,
        comentariosAdicionales,
        atencion,
        rango,
        fechaInicioOriginal,
        fechaInicioVigente,
        fechaPago,
        numerodeTecnicos,
        bono,
        costoAdicional,
        nroOperacion,
      } = asignacion;

      return new Promise((resolveFunction, rejectFunction) => {
        this.$apollo
          .mutate({
            mutation: UpdateAsignacion,
            variables: {
              input: {
                descripcionServicio,
                direccionLocal,
                latitud,
                longitud,
                estado: nuevoEstado,
                contratistaId,
                supervisorId,
                finanzasId,
                localId,
                tiempoEntrega,
                ofertaValida,
                correoAtencion,
                celularAtencion,
                codigoTicket,
                observaciones,
                comentariosAdicionales,
                atencion,
                rango,
                fechaInicioOriginal,
                fechaInicioVigente,
                fechaPago,
                numerodeTecnicos,
                bono,
                costoAdicional,
                nroOperacion,
              },
            },
          })
          .then((response) => {
            resolveFunction(response);
          })
          .catch((err) => {
            rejectFunction(err);
          });
      });
    },
  },
};

export default mixin;
